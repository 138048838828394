import request from '@/service/lib/request';

const module = 'company_order_item';

export function copyOrderItem(data) {
    return request({
        url: module + '/copy/' + data.id,
        method: 'get',
        data
    });
}

export function saveDrawingList(data) {
    return request({
        url: module + '/saveDrawingList/' + data.id,
        method: 'post',
        data
    });
}
