<template>
    <el-dialog title="零件图纸" v-if="OrderItemImageFormVisible" :visible.sync="OrderItemImageFormVisible" width="70%">
        <div>
            <el-form ref="formData" :model="item" :disabled="formDisable">
                <el-row :gutter="20">
                    <el-col>
                        <el-form-item label="零件图纸" :label-width="formLabelWidth">
                            <ImageListUpload
                                v-model="item.drawing_list"
                                :fileSize="10"
                                :fileLimit="20"
                                :fileType="fileTypeList"
                                listType="text"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" @click="onSubmit">确定</el-button>
                        <el-button @click="handleCancel">取消</el-button>
                    </el-form-item>
                </el-row>
            </el-form>

        </div>
    </el-dialog>
</template>
<script>
import ImageListUpload from '@/views/Component/ImageListUpload';
import {saveDrawingList} from "@/service/companyOrderItem";

export default {
    name: "OrderItemImageForm",
    components: {
        ImageListUpload
    },
    data() {
        return {
            formDisable: false,
            item: {
                id: null,
                drawing_list: []
            },
            formLabelWidth: '120px',
            OrderItemImageFormVisible: false,
            fileTypeList: ['png', 'jpg', 'jpeg', 'word', 'pdf']
        };
    },
    methods: {
        init(params) {
            this.OrderItemImageFormVisible = params.OrderItemImageFormVisible;
            this.$nextTick(() => {
                this.item = {
                    id: params.row.id,
                    drawing_list: params.row.drawing_list || []
                };
            });
        },
        onSubmit() {
            saveDrawingList(this.item).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$parent.getlist();
                    this.OrderItemImageFormVisible = false;
                }else{
                    this.$message({
                        message: res.message || '服务器错误',
                        type: 'error'
                    });
                }
            });
        },
        handleCancel() {
            this.item = {
                id: null,
                drawing_list: []
            };
            this.OrderItemImageFormVisible = false;
        }
    }
};
</script>

<style scoped>

</style>
