<template>
    <el-dialog title="工序信息" :visible.sync="formVisible" width="70%">
        <div>
            <el-form ref="formData" :model="form" :rules="rules" :disabled="formDisable">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="所属订单" :label-width="formLabelWidth">
                            <el-input v-model="form.orderName" autocomplete="off" disabled></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属零件" :label-width="formLabelWidth">
                            <el-input v-model="form.name" autocomplete="off" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="模板选择" :label-width="formLabelWidth">
                            <el-select filterable v-model="templateProcessId" placeholder="请选择" style="width: 60%;">
                                <el-option v-for="item in processTemplateList" :key="item.id" :label="item.process_name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                            <el-button type="primary" style="width: 20%;" @click="importProcessTemplate">导入工序模板
                            </el-button>
                        </el-form-item>

                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="form.items" border stripe :key="key">
                        <el-table-column label="工序名称">
                            <template slot-scope="{ row, $index }">
                                <el-form-item :prop="`items.${$index}.process_name`" :rules="rules.process_name">
                                    <el-input v-model="row.process_name"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属部门">
                            <template slot-scope="{ row, $index }">

                                <el-form-item :prop="`items.${$index}.company_group_id`" :rules="rules.company_group_id">
                                    <el-select filterable v-model="row.company_group_id" placeholder="请选择"
                                        style="width: 100%;">
                                        <el-option v-for="item in groupList" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="权重占比（%）">
                            <template slot-scope="{ row, $index }">
                                <el-form-item :prop="`items.${$index}.rate`" :rules="rules.rate">
                                    <el-input-number v-model="row.rate"></el-input-number>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量">
                            <template slot-scope="{ row, $index }">
                                <el-form-item :prop="`items.${$index}.num`" :rules="rules.num">
                                    <el-input disabled v-model="row.num"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="完成数量">
                            <template slot-scope="{ row, $index }">
                                <el-form-item :prop="`items.${$index}.complete_num`" :rules="rules.complete_num">
                                    <el-input disabled v-model="row.complete_num"></el-input>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="预计完成时间">
                            <template slot-scope="{ row, $index }">
                                <el-form-item :prop="`items.${$index}.plan_complete_time`" :rules="rules.plan_complete_time">
                                    <el-date-picker style="width: 100%;" v-model="row.plan_complete_time" type="datetime" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="250">
                            <template slot-scope="scope">
                                <el-button v-if="scope.$index > 0" type="danger" size="mini" @click="del(scope.$index)"
                                    icon='el-icon-delete'></el-button>
                                <el-button type="primary" size="mini" @click="add" icon='el-icon-plus'></el-button>
                                <el-button size="mini" :disabled="scope.$index === 0" @click="moveUp(scope.$index, scope.row)">
                                    <i class="el-icon-arrow-up"></i>
                                </el-button>
                                <el-button size="mini" :disabled="scope.$index === (form.items.length - 1)"
                                    @click="moveDown(scope.$index, scope.row)">
                                    <i class="el-icon-arrow-down"></i>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row>
                    <el-form-item style="text-align: center;">
                        <el-button type="primary" @click="onSubmit">确定</el-button>
                        <el-button @click="handleCancel">取消</el-button>
                    </el-form-item>
                </el-row>
            </el-form>

        </div>
    </el-dialog>
</template>

<script>
import { getCompanyOrderProcess, orderProcessSave } from "@/service/api";
import NP from 'number-precision';
export default {
    name: "Form",
    data() {
        return {
            form: {
                order_id: '',
                order_item_id: '',
                orderName: '',
                name: '',
                num: '',
                remark: '',
                no: '',
                items: [
                    { process_name: "", company_group_id: "", rate: "", num: '', id: "", plan_complete_time: "", complete_num: 0 }
                ]
            },
            rules: {
                process_name: [
                    { required: true, trigger: ["blur", "change"], message: "请输入" }
                ],
                company_group_id: [
                    { required: true, trigger: ["blur", "change"], message: "请输入" }
                ]
                // plan_complete_time: [
                //     { required: true, trigger: ["blur", "change"], message: "请输入" }
                // ]
            },
            formVisible: false,
            type: 'add',
            groupList: [],
            formLabelWidth: '100',
            labelPosition: 'right',
            templateProcessId: '',
            processTemplateList: [],
            key: '',
            formDisable: false
        };
    },
    methods: {
        init(params) {
            this.formVisible = params.formVisible;
            this.groupList = params.groupList;
            this.processTemplateList = params.processTemplateList;
            this.templateProcessId = "";
            if (params.row) {
                this.$nextTick(() => {
                    this.form = JSON.parse(JSON.stringify(params.row));
                    this.getOderProcessList();
                });
            } else {
                this.$refs.formData.resetFields();
                this.countRate();
            }
            this.type = params.type;
            this.formDisable = params.type === 'view' ? true : false;
        },
        onSubmit() {
            if (this.$refs['formData'].validate((valid) => {
                if (valid) {
                    orderProcessSave(this.form).then(() => {
                        this.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        this.$parent.getlist();
                        this.formVisible = false;
                    });
                } else {
                    return false;
                }
            }));
        },
        add() {
            this.form.items.push({
                process_name: "",
                rate: '',
                company_group_id: '',
                // plan_work_time: "",
                plan_complete_time: "",
                num: this.form.num,
                complete_num: 0
            });
            this.countRate();
        },
        del(index) {
            this.form.items.splice(index, 1);
            this.countRate();
        },
        moveUp(index) {
            let that = this;
            if (index > 0) {
                let upDate = that.form.items[index - 1];
                upDate.execSequence = index;
                that.form.items.splice(index - 1, 1);
                that.form.items[index - 1].execSequence = index - 1;
                that.form.items.splice(index, 0, upDate);
            } else {
                this.$message.warning("已经是第一条，不可上移");
            }
        },
        moveDown(index) {
            let that = this;
            if (index + 1 === that.form.items.length) {
                this.$message.warning("已经是最后一条，不可下移");
            } else {
                let downDate = that.form.items[index + 1];
                downDate.execSequence = index;
                that.form.items.splice(index + 1, 1);
                that.form.items.splice(index, 0, downDate);
                that.form.items[index + 1].execSequence = index + 1;
            }
        },
        handleCancel() {
            this.$refs.formData.resetFields();
            this.$nextTick(() => {
                this.formVisible = false;
            });
        },
        // 导入工序模板
        importProcessTemplate() {
            if (this.templateProcessId === '') {
                this.$message({
                    type: 'error',
                    message: '请先选择工序模板！'
                });
                return;
            }
            this.$confirm('此操作将覆盖当前的工序配置, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const find = this.processTemplateList.filter(item => item.id === this.templateProcessId)[0];
                if (find.items && find.items.length > 0) {
                    find.items.map((item) => {
                        item.num = this.form.num;
                        item.complete_num = 0;
                        item.id = null;
                        item.plan_complete_time = null;
                    });
                    this.form.items = JSON.parse(JSON.stringify(find.items));
                    this.countRate();
                }
                this.key = Math.random();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        getOderProcessList() {
            let data = { limit: 999, page: 1, order_item_id: this.form.order_item_id };
            getCompanyOrderProcess(data).then(res => {
                this.form.items = res.data.list;
                if (!this.form.items || this.form.items.length === 0) {
                    this.form.items[0] = {
                        process_name: "",
                        company_group_id: "",
                        rate: "",
                        plan_complete_time: "",
                        // plan_work_time: "",
                        num: this.form.num,
                        complete_num: 0,
                        id: ""
                    };
                }
                this.key = Math.random();
                this.countRate();
            });
        },
        // 每道工序平分权重
        countRate(){
            const itemCount = this.form.items.length;
            const rate = NP.round(NP.divide(100 / itemCount), 0);
            let allRate = 0;
            for(let i = 0; i < itemCount; i++) {
                const item = this.form.items[i];
                if(i === itemCount - 1) {
                    item.rate = NP.round(NP.minus(100 - allRate), 0);
                } else {
                    item.rate = rate;
                    allRate = NP.plus(allRate + rate);
                }
            }
        }
    }
};

export class form {
}
</script>

<style scoped>
.el-input {
    width: 80%;
}
</style>
