import request from '@/service/lib/request';
import {setUrlQuery} from "../utlis/url";

const module = 'company_order';

export function copyOrder(data) {
    return request({
        url: module + '/copy/' + data.id,
        method: 'get',
        data
    });
}

export function getList(data){
    return request({
        url: setUrlQuery({
            url: module,
            query: data
        }),
        method: 'get'
    });
}
