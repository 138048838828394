<template>
    <div class="elmain">
        <el-row>
            <el-form :inline="true">
                <el-form-item label="员工名称">
                    <el-input v-model="staff_name"></el-input>
                </el-form-item>
                <el-form-item label="操作时间">
                    <el-date-picker value-format="yyyy-MM-dd" @change="datack" v-model="createdAt" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="cx">查询</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-table :data="list" border style="width: 100%;margin-top: 20px;">
            <el-table-column prop="staff_name" label="员工名称"></el-table-column>
            <el-table-column prop="content" label="操作内容"></el-table-column>
            <el-table-column prop="created_at" label="操作时间"></el-table-column>
        </el-table>
        <div class="block" style="    margin-top: 10px;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit"
                           layout="sizes, prev, pager, next" :total="total">
            </el-pagination>

        </div>
    </div>
</template>

<script>
import {operationLogList} from "@/service/api";

export default {
    name: 'OperationLogList',
    data() {
        return {
            tableData: [],
            page: 1,
            name: '',
            limit: 10,
            list: [],
            total: 0,
            type: '',
            order_id: '',
            start_date: '',
            end_date: '',
            createdAt: [],
            pickerOptions: {
                shortcuts: [{
                    text: '三天',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);

                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }

                    }
                }, {
                    text: '一周',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }
                    }
                }, {
                    text: '半个月',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }
                    }
                }, {
                    text: '一个月',
                    onClick (picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        if (picker !== 'kk') {
                            picker.$emit('pick', [start, end]);
                            return [start, end];
                        } else {
                            return [start, end];
                        }
                    }
                }]
            },
            staff_name: ''
        };
    },
    methods: {
        cx() {
            this.page = 1;
            this.getlist();
        },
        handleSizeChange(val) {
            this.limit = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getlist();
        },
        datack (time) {
            this.start_date = time[0];
            this.end_date = time[1];
        },
        getlist() {
            let data = {
                limit: this.limit,
                page: this.page,
                start_date: this.start_date,
                end_date: this.end_date,
                staff_name: this.staff_name,
                order_id: this.order_id
            };
            operationLogList(data).then(res => {
                this.list = res.data.list;
                this.total = res.data.total;
            });
        },
        add() {
            this.dialogFormVisible = true;
            this.type = "add";
            this.dialogData = {
                name: '',
                code: '',
                company_group_id: '',
                is_manage: 0,
                manager_group_ids: ''
            };

        },
        init(params) {
            this.order_id = params.order_id;
            this.getlist();
        }
    }
};
</script>
<style scoped>
.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .main-conent {
        margin: 10px;
    }

    .elmain[data-v-7ad48fd0] {
        padding: 10px;
    }

    .el-dialog {
        width: 80% !important;
    }
}
</style>
