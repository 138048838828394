<template>
    <div class="elmain">
        <el-form ref="form" :model="params" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="客户名称">
                        <el-select filterable allow-create clearable v-model="params.customer_name" placeholder="请选择"
                                   style="width: 100%;">
                            <el-option v-for="item in customerlist" :key="item.id" :label="item.name"
                                       :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="负责人">
                        <el-select filterable allow-create clearable v-model="params.charge" placeholder="请选择"
                                   style="width: 100%;">
                            <el-option v-for="item in staffList" :key="item.id" :label="item.name" :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="下单日期">
                        <el-date-picker value-format="yyyy-MM-dd" v-model="params.order_date" clearable
                                        type="daterange" align="right" unlink-panels range-separator="至"
                                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="订单状态">
                        <el-select filterable clearable v-model="params.status" placeholder="请选择" style="width: 100%;">
                            <el-option v-for="item in orderStatusMap" :key="item.id" :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="关键字搜索">
                        <el-input placeholder="请输入零件名称、零件编号、图纸型号" v-model="params.keyword"
                                  autocomplete="off"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="25">
                    <el-button type="primary" @click="cx">查询</el-button>
                    <el-button type="success" @click="add">新增订单</el-button>
                    <el-button style="float: right;" type="warning" @click="templateDownLoad">零件导入模板下载</el-button>
                  <el-button style="float: right;" type="success" @click="exportToExcel">订单导出</el-button>
                </el-col>
            </el-row>
        </el-form>

        <el-table :data="list" border style="width: 100%;margin-top: 20px;" row-key="id">
            <el-table-column type="expand" width="50">
                <template slot-scope="scope">
                    <el-table
                        :data="scope.row.items"
                        :header-cell-style="{background: '#afccfd', color: '#000000'}"
                        style="width: 100%;margin-left: 50px;"
                        row-key="id"
                    >
                        <!--                        <el-table-column prop="no" label="编号"></el-table-column>-->
                        <el-table-column prop="no" label="编号" width="150"></el-table-column>
                        <el-table-column prop="drawing_model" label="图纸型号" width="150"></el-table-column>
                        <el-table-column prop="name" label="零件名称" width="120"></el-table-column>
                        <el-table-column prop="num" label="需求数量" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.num }}{{ scope.row.unit }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="complete_num" label="完成数量" width="100"></el-table-column>
                        <el-table-column prop="plan_end_time" label="计划完成时间" width="150"></el-table-column>
                        <el-table-column prop="material" label="材质" width="150"></el-table-column>
                        <el-table-column prop="end_time" label="实际完成时间" width="150"></el-table-column>
                        <el-table-column prop="status_name" label="当前状态" width="100"></el-table-column>
                        <el-table-column prop="progress" label="当前进度" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.progress + "%" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="remark" label="备注" width="150"></el-table-column>
                        <el-table-column prop="handle2" label="操作" width="280px">
                            <template slot-scope="scope">
                                <el-row :gutter="20" style="margin-bottom: 10px;">
                                    <el-col :span="8">
                                        <el-button v-if="scope.row.status == 1" @click="showProcess(scope.row, 'add')"
                                                   type="primary"
                                                   size="small">工序配置
                                        </el-button>
                                        <el-button v-else @click="showProcess(scope.row, 'view')" type="primary"
                                                   size="small">查看工序
                                        </el-button>
                                    </el-col>
                                    <el-col :span="6" v-if="scope.row.status == 1">
                                        <el-button @click="handleClickItem(scope.row)" type="warning" size="small">编辑
                                        </el-button>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-button type="danger" @click="deleteItem(scope.row)" size="small">删除
                                        </el-button>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="8">
                                        <el-button @click="showItemDrawing(scope.row)" type="success" size="small">零件图纸
                                        </el-button>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-button @click="copyOrderItem(scope.row)" type="warning" size="small">零件复制
                                        </el-button>
                                    </el-col>
                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column prop="no" label="订单号" width="150"></el-table-column>
            <el-table-column prop="name" label="订单名称" width="150"></el-table-column>
            <el-table-column prop="customer_name" label="客户名称" width="120"></el-table-column>
            <el-table-column prop="charge" label="负责人" width="100"></el-table-column>
            <el-table-column prop="order_time" label="下单日期" width="100"></el-table-column>
            <el-table-column prop="plan_end_time" label="计划完成时间" width="150"></el-table-column>
            <!--            <el-table-column prop="plan_work_time" label="计划工作时间" width="80"></el-table-column>-->
            <el-table-column prop="start_time" label="实际开始时间" width="150"></el-table-column>
            <el-table-column prop="end_time" label="实际结束时间" width="150"></el-table-column>
            <el-table-column prop="status_name" label="订单状态" width="100"></el-table-column>
            <el-table-column prop="progress" label="当前进度" width="100">
                <template slot-scope="scope">
                    {{ scope.row.progress + "%" }}
                </template>
            </el-table-column>
            <el-table-column prop="created_at" sortable label="创建时间" width="150"></el-table-column>
            <el-table-column prop="handle" label="操作" width="280px">
                <template slot-scope="scope">
                    <el-row :gutter="20" style="margin-bottom: 10px;">
                        <el-col :span="6">
                            <el-button @click="handleClick(scope.row)" type="warning" size="small">编辑</el-button>
                        </el-col>
                        <el-col :span="6">
                            <el-button type="danger" @click="deleteg(scope.row)" size="small">删除</el-button>
                        </el-col>
                        <el-col :span="8">
                            <el-button @click="copyOrder(scope.row)" type="warning" size="small">订单复制</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-button @click="showOperationLog(scope.row)" type="success" size="small">员工日志</el-button>
                        </el-col>
                        <el-col :span="8">
                            <el-button @click="importItems(scope.row)" type="primary" size="small">导入零件</el-button>
                        </el-col>
                        <el-col :span="8">
                            <el-button @click="addItems(scope.row)" type="primary" size="small">新增零件</el-button>
                        </el-col>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <div class="block" style="margin-top: 10px;">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page.sync="page"
                           :page-sizes="[5, 10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next"
                           :total="total">
            </el-pagination>

        </div>
        <!-- 修改框 -->
        <el-dialog title="订单信息" :visible.sync="dialogFormVisible" :append-to-body="true">
            <el-form :model="dialogData">
                <el-form-item label="订单号" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.no" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="订单名称" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="客户名称" :label-width="formLabelWidth">
                    <el-select filterable allow-create v-model="dialogData.customer_name" placeholder="请选择"
                               @blur="customerNameSelect"
                               style="width: 100%;">
                        <el-option v-for="item in customerlist" :key="item.id" :label="item.name" :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="负责人" :label-width="formLabelWidth">
                    <el-select
                        v-model="dialogData.charge_staff_id"
                        filterable
                        remote
                        reserve-keyword
                        :remote-method="getStaffListData"
                        :loading="staffListLoading"
                        @change="chargeChange"
                        default-first-option
                        placeholder="请选择">
                        <el-option
                            v-for="item in staffList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--                <el-form-item label="计划工作时长" :label-width="formLabelWidth">-->
                <!--                    <el-input v-model="dialogData.plan_work_time" autocomplete="off"></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item label="下单日期" :label-width="formLabelWidth">
                    <el-date-picker style="width: 100%;" v-model="dialogData.order_time" type="date" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="计划完成时间" :label-width="formLabelWidth">
                    <el-date-picker style="width: 100%;" v-model="dialogData.plan_end_time" type="date"
                                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="dialogData.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 修改框 -->
        <el-dialog title="零件信息" :visible.sync="itemVisiable">
            <el-form :model="itemData">
                <el-form-item label="所属订单" :label-width="formLabelWidth">
                    <el-input v-model="itemData.orderName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="编号" :label-width="formLabelWidth">
                    <el-input v-model="itemData.no" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="零件名称" :label-width="formLabelWidth">
                    <el-input v-model="itemData.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="数量" :label-width="formLabelWidth">
                    <el-input type="number" v-model="itemData.num" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="单位" :label-width="formLabelWidth">
                    <el-input v-model="itemData.unit" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计划完成时间" :label-width="formLabelWidth">
                    <el-date-picker style="width: 100%;" v-model="itemData.plan_end_time" type="date"
                                    placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="图纸型号" :label-width="formLabelWidth">
                    <el-input v-model="itemData.drawing_model" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="材质" :label-width="formLabelWidth">
                    <el-input v-model="itemData.material" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="itemData.remark" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="itemVisiable = false">取 消</el-button>
                <el-button type="primary" @click="submitItem">确 定</el-button>
            </div>
        </el-dialog>

        <ProcessForm ref="processForm" :visible.sync="processVisiable"/>

        <el-dialog title="员工操作日志" v-if="operationLogListVisiable" :visible.sync="operationLogListVisiable" width="80%">
            <OperationLogList ref="OperationLogList"></OperationLogList>
        </el-dialog>

        <OrderItemImageForm ref="OrderItemImageForm" :visible.sync="OrderItemImageFormVisible"></OrderItemImageForm>

        <el-dialog title="零件导入" :visible.sync="excelImportVisiable">
            <el-upload
                ref="excelImport"
                class="upload-demo"
                :action="importUrl"
                :headers="myHeaders"
                :data="importData"
                :on-success="uploadSuccess"
                :limit="1"
                :file-list="fileList"
            >
                <el-button type="info">excel导入<i class="el-icon-upload el-icon--right"/></el-button>
            </el-upload>
        </el-dialog>
    </div>
</template>

<script>
import {
    getcustomer,
    addCompanyOrder,
    putCompanyOrder,
    deleteCompanyOrder,
    addCompanyOrderItem,
    putCompanyOrderItem,
    deleteCompanyOrderItem,
    getCompanyOrderProcess,
    getProcessTemplate,
    group,
    getstaff
} from "@/service/api";
import {copyOrder, getList} from "@/service/companyOrder";
import {copyOrderItem} from "@/service/companyOrderItem";
import ProcessForm from "@/views/companyOrder/components/ProcessForm";
import OrderItemImageForm from "@/views/companyOrder/components/OrderItemImageForm";

import OperationLogList from "@/views/companyOrder/components/OperationLogList";
import {getToken} from "@/service/lib/localToken";
import * as XLSX from 'xlsx';
export default {
    data() {
        return {
            tableData: [],
            page: 1,
            name: '',
            limit: 10,
            list: [],
            total: 0,
            customerlist: [],
            dialogFormVisible: false,
            formLabelWidth: '120px',
            dialogData: {
                name: '',
                customer_name: '',
                charge: '',
                plan_work_time: '',
                plan_end_time: '',
                order_time: '',
                remark: '',
                no: '',
                charge_staff_id: ''
            },
            type: '',
            itemsTitle: '',
            itemVisiable: false,
            itemData: {
                order_id: '',
                orderName: '',
                name: '',
                num: '',
                remark: '',
                no: '',
                unit: '',
                plan_end_time: '',
                plan_work_time: '',
                drawing_model: '',
                material: ''
            },
            processVisiable: false,
            orderProcessList: [],
            orderId: '',
            orderItemId: '',
            itemType: '',
            processType: '',
            processTemplateList: [],
            orderProcessVisiable: false,
            grouplist: [],
            materialInfo: {},
            operationLogListVisiable: false,
            staffList: [],
            staffListLoading: false,
            params: {
                customer_name: '',
                charge: '',
                keyword: '',
                status: '',
                order_date: ''
            },
            orderStatusMap: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '三天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
                            if (picker !== 'kk') {
                                picker.$emit('pick', [start, end]);
                                return [start, end];
                            } else {
                                return [start, end];
                            }

                        }
                    },
                    {
                        text: '一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            if (picker !== 'kk') {
                                picker.$emit('pick', [start, end]);
                                return [start, end];
                            } else {
                                return [start, end];
                            }
                        }
                    },
                    {
                        text: '半个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
                            if (picker !== 'kk') {
                                picker.$emit('pick', [start, end]);
                                return [start, end];
                            } else {
                                return [start, end];
                            }
                        }
                    },
                    {
                        text: '一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            if (picker !== 'kk') {
                                picker.$emit('pick', [start, end]);
                                return [start, end];
                            } else {
                                return [start, end];
                            }
                        }
                    },
                    {
                        text: '一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            if (picker !== 'kk') {
                                picker.$emit('pick', [start, end]);
                                return [start, end];
                            } else {
                                return [start, end];
                            }
                        }
                    }
                ]
            },
            OrderItemImageFormVisible: false,
            importUrl: `${process.env.VUE_APP_APIHOST}` + `/company_order_item/importData`,
            myHeaders: {
                authorization: 'Bearer ' + getToken()
            },
            importData: {
                order_id: ''
            },
            fileList: [],
            excelImportVisiable: false
        };
    },
    components: {ProcessForm, OperationLogList, OrderItemImageForm},
    methods: {
        exportToExcel() {
        const dataToExport = this.list.map(item => ({
                '订单编号': item.no,
                '订单名称': item.name,
                '客户名称': item.customer_name, 
                '负责人': item.charge,
                '下单日期': item.order_time,
                '交期时间': item.plan_end_time,
                '实际开始时间': item.actual_start_time,
                '实际结束时间': item.actual_end_time
            }));

            // 将数据转换为工作表
            const ws = XLSX.utils.json_to_sheet(dataToExport);

            // 创建带有工作表的工作簿
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, '订单数据');

            // 将工作簿保存为Excel文件
            XLSX.writeFile(wb, '订单数据.xlsx');
        },

        getStaffListData(name) {
            let data = {
                limit: 30,
                page: 1,
                name: name,
                company_group_id: ''
            };
            this.staffListLoading = true;
            getstaff(data).then(res => {
                this.staffList = res.data.list;
                this.staffListLoading = false;
                // this.total = res.data.total;
            });
        },
        chargeChange() {
            // 根据选中的 charge_staff_id 查找对应的姓名
            const selectedStaff = this.staffList.find(
                (item) => item.id === this.dialogData.charge_staff_id
            );
            if (selectedStaff) {
                console.log(selectedStaff);
                this.dialogData.charge = selectedStaff.name;
            }
        },
        // 复制订单
        copyOrder(row) {
            const that = this;
            const orderId = row.id;
            this.$confirm('确定复制该订单吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: orderId
                };
                copyOrder(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // 复制订单零件
        copyOrderItem(row) {
            const that = this;
            const orderId = row.id;
            this.$confirm('确定复制该订单零件吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: orderId
                };
                copyOrderItem(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        addItems(row) {
            this.itemVisiable = true;
            this.itemType = "add";
            this.itemData = {
                order_id: row.id,
                orderName: row.name,
                name: '',
                num: '',
                remark: '',
                no: '',
                unit: '',
                plan_end_time: '',
                plan_work_time: '',
                drawing_model: '',
                material: ''
            };
        },
        submitItem() {
            if (this.itemData.no === '') {
                this.$message.error('请输入编号');
                return;
            }
            if (this.itemData.material_name === '') {
                this.$message.error('请输入零件名称');
                return;
            }
            if (this.itemData.num === '') {
                this.$message.error('请输入数量');
                return;
            }
            if (this.itemData.plan_end_time === '') {
                this.$message.error('请输入计划完成时间');
                return;
            }
            // if (this.itemData.plan_work_time === '' || this.itemData.plan_work_time === 0) {
            //     this.$message.error('请输入计划工作时间');
            //     return;
            // }
            this.itemData.num = Number(this.itemData.num);
            this.itemData.plan_end_time = this.dayjs(this.itemData.plan_end_time).format('YYYY-MM-DD');
            if (this.itemType === 'add') {
                addCompanyOrderItem(this.itemData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.itemVisiable = false;
                    }
                });
            }
            if (this.itemType === 'put') {
                putCompanyOrderItem(this.itemData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.itemVisiable = false;
                    }
                });
            }
        },
        handleClickItem(row) {
            this.itemVisiable = true;
            this.itemType = "put";
            this.itemData = {
                order_id: row.orderId,
                orderName: row.orderName,
                name: row.name,
                no: row.no,
                num: row.num,
                remark: row.remark,
                id: row.id,
                plan_work_time: row.plan_work_time,
                plan_end_time: row.plan_end_time,
                unit: row.unit,
                drawing_model: row.drawing_model,
                material: row.material
            };
        },
        // 工序配置
        showProcess(row, type) {
            this.$nextTick(() => {
                const params = {
                    row: JSON.parse(JSON.stringify(row)),
                    formVisible: true,
                    type: type,
                    groupList: this.grouplist,
                    processTemplateList: this.processTemplateList
                };
                params.row.order_item_id = row.id;
                this.$refs.processForm.init(params);
            });
        },
        // 新增工序弹窗
        addProcess() {
            this.orderProcessVisiable = true;
            this.processType = "add";
            this.processData = {
                order_id: this.materialInfo.order_id,
                order_item_id: this.materialInfo.id,
                process_name: '',
                num: this.materialInfo.num,
                rate: '',
                company_group_id: '',
                sort: 1
            };
        },
        swgroup(row) {
            let text = '';
            this.grouplist.forEach(item => {
                if (item.id === row.company_group_id) {
                    text = item.name;
                }
            });
            return text;
        },
        getOderProcessList() {
            let data = {limit: 999, page: 1, order_item_id: this.materialInfo.id};
            getCompanyOrderProcess(data).then(res => {
                this.orderProcessList = res.data.list;
            });
        },
        getProcessTemplateList() {
            let data = {limit: 100, page: 1, name: ''};
            getProcessTemplate(data).then(res => {
                this.processTemplateList = res.data.list;
                this.total = res.data.total;
            });
        },
        deleteItem(i) {
            console.log(i);
            let that = this;
            this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id
                };
                deleteCompanyOrderItem(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });

                    }

                });

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        cx() {
            this.page = 1;
            this.getlist();
        },
        handleClick(row) {
            this.dialogFormVisible = true;
            this.type = "put";
            this.dialogData = JSON.parse(JSON.stringify(row));
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.limit = val;
            this.getlist();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getlist();
        },
        getlist() {
            const data = Object.assign({limit: this.limit, page: this.page}, this.params);
            if (data.order_date) {
                data.order_time_start = data.order_date[0];
                data.order_time_end = data.order_date[1];
            }
            getList(data).then(res => {
                res.data.list.forEach(item => {
                    item.order_date = item.order_date ? this.dayjs(item.order_date).format('YYYY-MM-DD') : '无';
                    item.delivery_date = item.delivery_date ? this.dayjs(item.delivery_date).format('YYYY-MM-DD') : '无';
                    item.items.forEach(material => {
                        material.customer_name = material.name;
                        material.orderName = item.name;
                        material.charge = material.num;
                    });
                });
                this.list = res.data.list;
                this.total = res.data.total;
                this.orderStatusMap = res.data.orderStatusMap;
            });
        },
        getcustomer() {
            getcustomer({limit: 100, page: 1, name: ''}).then(res => {
                this.customerlist = res.data.list;
            });
        },
        add() {
            this.dialogFormVisible = true;
            this.type = "add";
            this.dialogData = {
                name: '',
                customer_name: '',
                charge: '',
                plan_work_time: '',
                plan_end_time: '',
                order_time: '',
                remark: '',
                charge_staff_id: ''
            };

        },
        deleteg(i) {
            console.log(i);
            let that = this;
            this.$confirm('此操作将永久删除该订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: i.id
                };
                deleteCompanyOrder(data).then(res => {
                    if (res.code === 200) {
                        that.getlist();
                        that.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        submit() {
            if (this.dialogData.name === '') {
                this.$message.error('请输入订单名称');
                return;
            }
            if (this.dialogData.customer_name === '') {
                this.$message.error('请输入客户名称');
                return;
            }
            this.dialogData.plan_work_time = Number(this.dialogData.plan_work_time);
            this.dialogData.order_time = this.dayjs(this.dialogData.order_time).format('YYYY-MM-DD');
            this.dialogData.plan_end_time = this.dayjs(this.dialogData.plan_end_time).format('YYYY-MM-DD');
            if (this.type === 'add') {
                addCompanyOrder(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }

                });
            }
            if (this.type === 'put') {

                putCompanyOrder(this.dialogData).then(res => {
                    if (res.code === 200) {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.getlist();
                        this.dialogFormVisible = false;
                    }
                });
            }
        },
        getgroup() {
            group({limit: 100, page: 1}).then(res => {
                this.grouplist = res.data.list;
            });
        },
        // 展示员工操作日志
        showOperationLog(row) {
            this.operationLogListVisiable = true;
            this.$nextTick(() => {
                const params = {
                    order_id: row.id
                };
                this.$refs.OperationLogList.init(params);
            });
        },
        // 工序配置
        showItemDrawing(row) {
            this.$nextTick(() => {
                const params = {
                    row: JSON.parse(JSON.stringify(row)),
                    OrderItemImageFormVisible: true
                };
                params.row.order_item_id = row.id;
                this.$refs.OrderItemImageForm.init(params);
            });
        },
        templateDownLoad() {
            let url = process.env.VUE_APP_APIHOST + '/company_order_item/generateTemplate';
            window.location.href = url;
            window.open(url);
        },
        importItems(row) {
            this.excelImportVisiable = true;
            this.importData.order_id = row.id;
        },
        // 导入数据成功后的钩子函数
        uploadSuccess(res) {
            this.excelImportVisiable = false;
            this.$message.success(res.message);
            this.$refs.excelImport.clearFiles();
            this.getlist();
        },
        // handleCustomerNameChange(value) {
        //     // 检查用户是否手动输入了选项
        //     const selectedOption = this.customerlist.find(
        //         (item) => item.name === value
        //     );
        // },
        customerNameSelect(e) {
            let value = e.target.value; // 输入框值
            if (value) { // 你输入才有这个值 不为空，如果你下拉框选择的话 这个值为空
                this.dialogData.customer_name = value;
            }
        }
    },
    mounted() {
        console.log(this.myHeaders);
        this.getlist();
        this.getcustomer();
        this.getProcessTemplateList();
        this.getgroup();
      
    }
};
</script>
<style scoped>
.elmain {
    padding: 50px;
}

@media screen and (max-width: 750px) {
    .main-conent {
        margin: 10px;
    }

    .elmain[data-v-7ad48fd0] {
        padding: 10px;
    }

    .el-dialog {
        width: 90% !important;
    }
}

.demo-table-expand {
    font-size: 0;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>
